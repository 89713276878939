<template>
  <el-form
    ref="form"
    v-cus-loading="form.loading"
    :model="form.model"
    :rules="form.rules"
  >
    <el-form-item :label="$tc('status')" prop="voucherStatus">
      <select-voucher-status-update
        v-model="form.model.voucherStatus"
        @change="form.model.voucherStatusReason = ''"
      ></select-voucher-status-update>
    </el-form-item>
    <el-form-item
      v-if="
        form.model.voucherStatus === 'Annuled' ||
        form.model.voucherStatus === 'PaidWithoutInvoice'
      "
      :label="$tc('reason')"
      prop="voucherStatusReason"
    >
      <el-input
        v-model="form.model.voucherStatusReason"
        type="textarea"
        :rows="2"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        v-if="form.model.voucherStatus === 'Paid'"
        size="mini"
        type="primary"
        @click="$emit('paid', form.model)"
      >
        {{ $tc('pay') }}
      </el-button>
      <el-button v-else size="mini" type="primary" @click="onSubmit">
        {{ $tc('save') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import form from '@/views/common/form'
import { mapGetters } from 'vuex'
import model from './model'
import rules from './rules'

export default {
  props: {
    model: { required: false },
  },
  data() {
    return {
      form: form({ model, rules }),
    }
  },
  methods: {
    onSubmit() {
      const _this = this
      _this.$refs.form.validate(valid => {
        if (valid) {
          _this.form.loading = true

          _this.$http
            .patch(`vouchers/${_this.form.model.id}/status`, {
              voucherStatus:
                _this.form.model.voucherStatus === 'PaidWithoutInvoice'
                  ? 'Paid'
                  : _this.form.model.voucherStatus,
              voucherStatusReason: _this.form.model.voucherStatusReason,
              voucherUpdatedBy: _this.setting.username,
            })
            .then(_ => {
              _this.$message({
                type: 'success',
                message: _this.$tc('processSuccess'),
              })
              _this.$emit('updated', _this.form.model)
            })
            .finally(_ => {
              _this.form.loading = false
            })
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')
        }
      })
    },
  },
  computed: {
    ...mapGetters(['setting']),
  },
  created() {
    this.form.model = this.model
  },
}
</script>
